import Axios from "axios";
import { useEffect, useState } from "react";

import "./App.css";

function App() {
  return <Comments id={window.location.hash.slice(1)} />;
}

export default App;

export const Comments = ({ id }) => {
  const [father, setFather] = useState(0);
  const [nick, setNick] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    window.addEventListener("message", function (dataPassed) {
      const match = dataPassed.data.match(/father: ([0-9]+)/);
      if (match) setFather(match[1]);
    });
  }, []);

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!content || !nick) {
            alert("Musisz wypełnić wszystkie pola!");
            return;
          }

          if (!window.grecaptcha) {
            alert("Coś poszło nie tak");
            return;
          }
          window.grecaptcha.ready(function () {
            window.grecaptcha
              .execute("6LdP2rUUAAAAAJ-BaSbH3PKkf6XBB-K2Ph38Pr7F", {
                action: "submit",
              })
              .then(function (token) {
                Axios.post(`https://service.histmag.org/createComment`, {
                  nick,
                  post: id,
                  content,
                  father,
                  token,
                });
              });
          });

          setContent("");
          setFather(0);
          setNick("");

          alert(
            "Twój komentarz trafił do moderacji serwisu. Opublikujemy go po zatwierdzeniu go przez moderatora."
          );
        }}
      >
        <div>
          <input
            value={nick}
            onChange={(e) => setNick(e.target.value)}
            style={{
              width: "100%",
              height: "2em",
              lineHeight: "2em",
              padding: 4,
              fontSize: 16,
              marginBottom: 2,
              border: "1px solid rgba(0,0,0,0.3)",
              background: "white",
            }}
            placeholder="Wpisz nick..."
          />
        </div>

        {father !== 0 && <div>Odpowiadasz na komentarz zaznaczony poniżej</div>}

        <textarea
          placeholder="Treść komentarza..."
          style={{
            width: "100%",
            height: "4.5em",
            lineHeight: "1.5em",
            padding: 4,
            fontSize: 16,
            border: "1px solid rgba(0,0,0,0.3)",
            background: "white",
            margin: "8px 0",
          }}
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
        <button
          style={{
            padding: 8,
            border: "1px solid rgba(0,0,0,0.3)",
          }}
          type="submit"
        >
          Dodaj komentarz
        </button>
      </form>
    </div>
  );
};
